<template>
	<div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Promoter Payroll Management</p>
                <p class="text-title mb-0">You do not have permission to view all promoter payrolls</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-select dense clearable label="Year" :items="years" :menu-props="{top: false, offsetY: true}" v-model="filters.year"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.year"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start_date"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end_date"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.quantity"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y v-if="hasMenu(item)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item target="_blank" :to="getViewUrl(item.id)" v-if="item.can_view">
                                                <v-list-item-title>View Info</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { makeYearList } from '@/utils/dates'
import MessageNotifier from '../../../shared/MessageNotifier.vue'

export default {
	name: 'PayrollProjectList',
	components: {
        messageNotifier: MessageNotifier,
	},
	data () {
		return {
            loading: false,
			filters: {
				year: ''
			},
			permissions: {},
			items: [],
			headers: []
		}
	},
	computed: {
		notifier() {
            return this.$refs.notifier
        },
        years() {
            return makeYearList(2021)
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/payroll/getPayrollPromoters',  this.filters).then((response) => {
				this.items = response.data.items
				this.headers = response.data.headers
				this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.get()
		},
		reset: function() {
			this.filters = { year: '' }
		},
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        getViewUrl: function(id){
            return '/epanel/payroll/promoter/' + id + '/info'
        },
        hasMenu: function(item) {
            return item.can_view
        }
	}
}

</script>